import { Section } from 'react-scroll-section';
import { features } from '../constants';
import styles, { layout } from '../style';

const FeatureCard = ({ icon, title, content }) => (
  <div className='flex flex-row p-6 rounded-[20px] feature-card'>
    <div
      className={`w-[64px] h-[64px] rounded-full ${styles.flexCenter} bg-dimBlue`}
    >
      <img src={icon} alt='icon' className='w-[80%] h-[80%] object-contain' />
    </div>
    <div className='flex-1 flex flex-col ml-12'>
      <h4 className='font-poppins font-semibold text-white text-[18px] leading-[23.4px] mb-1'>
        {title}
      </h4>
      <ul className='list-disc list-inside'>
        {content.map((item) => (
          <li
            key={item}
            className='font-poppins font-normal text-dimWhite text-[16px] leading-[24px]'
          >
            {item}
          </li>
        ))}
      </ul>
    </div>
  </div>
);

const Business = () => (
  <Section id='roadmap' className='sm:py-16 py-6 flex flex-col'>
    <div className={`${layout.sectionImg} flex-col`}>
      <div className='flex flex-row items-center py-4 pl-4 pr-12 bg-discount-gradient rounded-[10px] mb-2'>
        <div
          className={`w-[48px] h-[48px] rounded-full ${styles.flexCenter} bg-dimBlue`}
        >
          <svg
            className='w-[32px] h-[32px]'
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={1.5}
            stroke='cyan'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941'
            />
          </svg>
        </div>

        <p className='font-poppins font-normal leading-[30.8px] text-white ml-2 text-3xl'>
          ROADMAP
        </p>
      </div>
    </div>

    <div className='grid grid-cols-1 md:grid-cols-2 my-12 gap-4'>
      {features.map((feature) => (
        <FeatureCard key={feature.id} {...feature} />
      ))}
    </div>
  </Section>
);

export default Business;
