import { icon1, icon2, icon3, icon4, icon5 } from '../assets/roadmap';

import instagram from '../assets/instagram.png';
import twitter from '../assets/twitter.png';
import discord from '../assets/discord.png';
import tiktok from '../assets/tiktok.png';

export const navLinks = [
  {
    id: 'ooville',
    title: "OO'sVille",
  },
  {
    id: 'opensea',
    title: 'Opensea',
  },
  {
    id: 'map',
    title: 'Map',
  },
];

export const features = [
  {
    id: 'feature-1',
    icon: icon1,
    title: 'Planting Seeds',
    content: [
      "OO'SVille Items creation 25 charachters",
      'Merging preview',
      'Project website launch: Shintoon.io',
      'Animation concept and its attributes',
      'Marketing promotion',
    ],
  },
  {
    id: 'feature-2',
    icon: icon2,
    title: 'Phase 1 - until 2022',
    content: [
      'Dropping first 25 charachters of the collection!',
      'Forming partnerships with reputable NFT collections',
      'Every 40th drop will be rare!',
      'Public new features on our website!',
      'Running big marketing campaigns',
    ],
  },
  {
    id: 'feature-3',
    icon: icon3,
    title: 'Phase 2 - Mid 2023',
    content: [
      'Every 40th drop will be rare!',
      "Publish OO'SVille on our website!",
      'Publish our Token and disperse it between our holders!',
      'Marketing promotion',
    ],
  },
  {
    id: 'feature-4',
    icon: icon4,
    title: 'Phase 3 - Late 2023',
    content: [
      'Every 40th drop will be rare!',
      "Publish OO'SVille Animation (First Season)",
      "Publish OO'SVille Mobile Game",
      'Running big marketing campaigns',
    ],
  },
  {
    id: 'feature-5',
    icon: icon5,
    title: 'Final Phase - Coming soon',
    content: [
      'Drop 375 NFTs',
      'Every 40th drop will be rare!',
      "Publish OO'SVille Animation (Second Season)",
    ],
  },
];

export const socialMedia = [
  {
    id: 'social-media-1',
    icon: discord,
    link: 'https://www.instagram.com/shintoonofficial/',
  },
  {
    id: 'social-media-2',
    icon: instagram,
    link: 'https://www.instagram.com/shintoonofficial/',
  },
  {
    id: 'social-media-3',
    icon: tiktok,
    link: 'https://www.tiktok.com/@shintoondesign?lang=en',
  },
  {
    id: 'social-media-4',
    icon: twitter,
    link: 'https://twitter.com/shintoonnft',
  },
];
