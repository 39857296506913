import React from 'react';
import styles from '../style';
import Sina from '../assets/Sina.webp';
import Shima from '../assets/Shima.webp';

function Team() {
  return (
    <div>
      <div className='container flex justify-center mx-auto pt-16'>
        <div>
          <h1
            className={`${styles.heading2} font-poppins xl:text-5xl text-4xl text-center text-gray-300 font-semibold pb-2 sm:w-4/6 w-5/6 mx-auto`}
          >
            The Directors
          </h1>
          <h1 className='font-poppins xl:text-4xl md:text-3xl text-2xl text-center text-gray-300 font-medium pb-6 sm:w-4/6 w-5/6 mx-auto text-gradient'>
            The Talented People behind the Shintoon Project.
          </h1>
        </div>
      </div>
      <div className='w-full pt-10'>
        <div className='container mx-auto'>
          <div className='lg:flex md:flex sm:flex items-center xl:justify-between flex-wrap md:justify-around sm:justify-around lg:justify-around'>
            <div className='bg-black-gradient-2 rounded-[20px] box-shadow xl:w-1/3 sm:w-3/4 md:w-2/5 relative mt-16 mb-32 sm:mb-24 xl:max-w-sm lg:w-2/5'>
              <div className='rounded overflow-hidden shadow-md'>
                <div className='absolute -mt-20 w-full flex justify-center'>
                  <div className='h-32 w-32'>
                    <img
                      src={Sina}
                      alt='Sina'
                      className='rounded-full object-cover h-full w-full shadow-md'
                    />
                  </div>
                </div>
                <div className='px-6 mt-16 pb-16'>
                  <div className='font-bold text-3xl text-center pb-1 text-white'>
                    Sina
                  </div>
                  <p className='text-gray-100 text-sm text-center'>
                    Founder &amp; Art Director
                  </p>
                  <p className='text-center text-gray-300 text-base pt-3 font-normal'>
                    Came up with the business idea and acted on it. Manages
                    visual elements for a publication, product. Responsible for
                    designing and overseeing the vision for layouts, imagery,
                    set designs
                  </p>
                  {/* <div className='w-full flex justify-center pt-5 pb-5'>
                    <a href='javascript:void(0)' className='mx-5'>
                      <div>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width={24}
                          height={24}
                          viewBox='0 0 24 24'
                          fill='none'
                          stroke='#718096'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          className='feather feather-github'
                        >
                          <path d='M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22' />
                        </svg>
                      </div>
                    </a>
                    <a href='javascript:void(0)' className='mx-5'>
                      <div>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width={24}
                          height={24}
                          viewBox='0 0 24 24'
                          fill='none'
                          stroke='#718096'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          className='feather feather-twitter'
                        >
                          <path d='M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z' />
                        </svg>
                      </div>
                    </a>
                    <a href='javascript:void(0)' className='mx-5'>
                      <div>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width={24}
                          height={24}
                          viewBox='0 0 24 24'
                          fill='none'
                          stroke='#718096'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          className='feather feather-instagram'
                        >
                          <rect
                            x={2}
                            y={2}
                            width={20}
                            height={20}
                            rx={5}
                            ry={5}
                          />
                          <path d='M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z' />
                          <line x1='17.5' y1='6.5' x2='17.51' y2='6.5' />
                        </svg>
                      </div>
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
            <div className='bg-black-gradient-2 rounded-[20px] box-shadow xl:w-1/3 sm:w-3/4 md:w-2/5 relative mt-16 mb-32 sm:mb-24 xl:max-w-sm lg:w-2/5'>
              <div className='rounded overflow-hidden shadow-md'>
                <div className='absolute -mt-20 w-full flex justify-center'>
                  <div className='h-32 w-32'>
                    <img
                      src={Shima}
                      alt='Sina'
                      className='rounded-full object-cover h-full w-full shadow-md'
                    />
                  </div>
                </div>
                <div className='px-6 mt-16 pb-16'>
                  <div className='font-bold text-3xl text-center pb-1 text-white'>
                    Shima
                  </div>
                  <p className='text-gray-100 text-sm text-center'>
                    CEO &amp; Co Founder
                  </p>
                  <p className='text-center text-gray-300 text-base pt-3 font-normal'>
                    The CEO's role in raising a company's corporate IQ is to
                    establish an atmosphere that promotes knowledge sharing and
                    collaboration. Guiding and leading the team to bring about
                    success.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Team;
