import { signOut } from 'firebase/auth';
import { useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useScrollSection } from 'react-scroll-section';

import { close, logo, menu, oosLogo } from '../assets';
import { auth } from '../firebase';

const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  const [user, loading, error] = useAuthState(auth);
  const roadMapSection = useScrollSection('roadmap');
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const signOutUser = () =>
    signOut(auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });

  console.log(pathname);

  return (
    <nav className='w-full flex py-6 justify-between items-center navbar'>
      {pathname == '/' ? (
        <img src={logo} alt='Shintoon' className='w-[124px]' />
      ) : (
        <img src={oosLogo} alt='OOsville' className='w-[124px] sm:w-[200px]' />
      )}

      <ul className='list-none sm:flex hidden justify-end items-center flex-1'>
        {pathname == '/' ? (
          <li className='mr-10 font-poppins font-medium cursor-pointer text-[16px] text-dimWhite'>
            <Link to='/oosville'>OO'sVille</Link>
          </li>
        ) : (
          <li className='mr-10 font-poppins font-medium cursor-pointer text-[16px] text-dimWhite'>
            <Link to='/'>Home</Link>
          </li>
        )}
        <li className='mr-10 font-poppins font-medium cursor-pointer text-[16px] text-dimWhite'>
          <a
            href='https://opensea.io/collection/oosville'
            target={'_blank'}
            rel='noreferrer'
          >
            OpenSea
          </a>
        </li>
        {pathname == '/' ? (
          <li
            onClick={roadMapSection.onClick}
            className='mr-10 font-poppins font-medium cursor-pointer text-[16px] text-dimWhite'
          >
            <p>Roadmap</p>
          </li>
        ) : (
          <li className='mr-10 font-poppins font-medium cursor-pointer text-[16px] text-dimWhite'>
            <Link to='/roadmap'>Roadmap</Link>
          </li>
        )}
        {user ? (
          <button
            type='button'
            onClick={signOutUser}
            className={`ml-12 py-2 px-4 font-poppins font-medium text-[16px] text-primary bg-blue-gradient rounded-[8px] outline-none `}
          >
            Sign Out
          </button>
        ) : (
          <button
            type='button'
            onClick={() => navigate('/signin')}
            className={`ml-12 py-2 px-4 font-poppins font-medium text-[16px] text-primary bg-blue-gradient rounded-[8px] outline-none `}
          >
            Sign In
          </button>
        )}
      </ul>

      <div className='sm:hidden flex flex-1 justify-end items-center z-10'>
        <img
          src={toggle ? close : menu}
          alt='menu'
          className='w-[28px] h-[28px] object-contain'
          onClick={() => setToggle(!toggle)}
        />
        <div
          className={`${
            !toggle ? 'hidden' : 'flex'
          } p-6 bg-black-gradient absolute top-20 right-0 mx-4 my-2 min-w-[140px] rounded-xl sidebar`}
        >
          <ul className='list-none flex justify-end items-start flex-1 flex-col'>
            {pathname == '/' ? (
              <li className='font-poppins font-medium cursor-pointer text-[16px] text-dimWhite mb-4'>
                <Link to='/oosville'>OO'sVille</Link>
              </li>
            ) : (
              <li className='font-poppins font-medium cursor-pointer text-[16px] text-dimWhite mb-4'>
                <Link to='/'>Home</Link>
              </li>
            )}
            <li className='font-poppins font-medium cursor-pointer text-[16px] text-dimWhite mb-4'>
              <a
                href='https://opensea.io/collection/oosville'
                target={'_blank'}
                rel='noreferrer'
              >
                OpenSea
              </a>
            </li>
            {pathname == '/' ? (
              <li
                onClick={roadMapSection.onClick}
                className='font-poppins font-medium cursor-pointer text-[16px] text-dimWhite mb-4'
              >
                <p>Roadmap</p>
              </li>
            ) : (
              <li className='font-poppins font-medium cursor-pointer text-[16px] text-dimWhite mb-4'>
                <Link to='/roadmap'>Roadmap</Link>
              </li>
            )}
            {user ? (
              <button
                type='button'
                onClick={signOutUser}
                className={`py-1 px-2 font-poppins font-medium text-[12px] text-primary bg-blue-gradient rounded-[8px] outline-none `}
              >
                Sign Out
              </button>
            ) : (
              <button
                type='button'
                onClick={() => navigate('/signin')}
                className={`py-1 px-2 font-poppins font-medium text-[12px] text-primary bg-blue-gradient rounded-[8px] outline-none `}
              >
                Sign In
              </button>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
