import React, { useEffect, useState } from 'react';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { auth, db } from '../../firebase';
import styles from '../../style';
import { Footer, Navbar } from '../../components';
import parse from 'html-react-parser';

import oneE from '../../assets/rating/01-E.png';
import twoE from '../../assets/rating/02-E.png';
import threeE from '../../assets/rating/03-E.png';
import fourE from '../../assets/rating/04-E.png';
import fiveE from '../../assets/rating/05-E.png';
import one from '../../assets/rating/01-F.png';
import two from '../../assets/rating/02-F.png';
import three from '../../assets/rating/03-F.png';
import four from '../../assets/rating/04-F.png';
import five from '../../assets/rating/05-F.png';
import { useAuthState } from 'react-firebase-hooks/auth';
import { MagnifyingGlass } from 'react-loader-spinner';

const CharacterPage = () => {
  let { characterName } = useParams();
  console.log(characterName);
  const [character, setCharacter] = useState(null);
  const [rating, setRating] = useState(null);
  const [id, setId] = useState(null);
  const [userRating, setUserRating] = useState(null);
  const [hasUserRated, setHasUserRated] = useState(false);
  const [user, loading, error] = useAuthState(auth);

  const getCharacter = async () => {
    const q = query(
      collection(db, 'Characters'),
      where('characterName', '==', characterName)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      if (doc.data()) {
        setId(doc.id);
        setCharacter(doc.data());
      }
    });
  };
  useEffect(() => {
    getCharacter();
    if (user) {
      getUserRating();
    }
  }, [user]);

  const getUserRating = async () => {
    const ratingsRef = collection(db, 'ratings');
    const q = query(
      ratingsRef,
      where('characterId', '==', id),
      where('userId', '==', user.uid)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      if (doc.data()) {
        console.log(doc.id, ' => ', doc.data());
        setHasUserRated(true);
        setRating(doc.data().rating);
        setUserRating(doc.data().rating);
      }
    });
  };

  const options = {
    replace: (domNode) => {
      if (domNode.attribs && domNode.attribs.class === 'remove') {
        return <></>;
      }
    },
  };

  const addRating = async () => {
    const docId = `${id}_${auth.currentUser.uid}`;
    const data = {
      characterId: id,
      userId: auth.currentUser.uid,
      rating,
    };
    await setDoc(doc(db, 'ratings', docId), data);
    getUserRating();
  };

  return (
    <div className='bg-primary w-full overflow-hidden text-white'>
      <div className={`${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}>
          <Navbar />
        </div>
      </div>

      {character ? (
        <div className={`relative ${styles.boxWidth} ${styles.paddingX}`}>
          <div class='relative md:fixed w-full md:w-1/2 md:min-h-screen inset-0'>
            <div className='w-full flex flex-col'>
              <img
                src={character.characterImage[0].downloadURL}
                alt={character.characterName}
              />
            </div>
            {/* <Footer /> */}
          </div>
          <div class='w-full md:w-1/2 ml-auto'>
            <div>
              <h3 className='text-[#F8DDD2] text-xl font-semibold my-3'>
                {character.characterName}
              </h3>
              {user ? (
                <div
                  className='flex space-x-2 my-4'
                  onMouseLeave={() => setRating(hasUserRated ? userRating : 0)}
                >
                  <img
                    src={rating >= 1 ? one : oneE}
                    alt=''
                    className='h-12 cursor-pointer'
                    onMouseEnter={() => setRating(1)}
                    onMouseLeave={() => setRating(0)}
                    onClick={addRating}
                  />
                  <img
                    src={rating >= 2 ? two : twoE}
                    alt=''
                    className='h-12 cursor-pointer'
                    onMouseEnter={() => setRating(2)}
                    onMouseLeave={() => setRating(1)}
                    onClick={addRating}
                  />
                  <img
                    src={rating >= 3 ? three : threeE}
                    alt=''
                    className='h-12 cursor-pointer'
                    onMouseEnter={() => setRating(3)}
                    onMouseLeave={() => setRating(2)}
                    onClick={addRating}
                  />
                  <img
                    src={rating >= 4 ? four : fourE}
                    alt=''
                    className='h-12 cursor-pointer'
                    onMouseEnter={() => setRating(4)}
                    onMouseLeave={() => setRating(3)}
                    onClick={addRating}
                  />
                  <img
                    src={rating >= 5 ? five : fiveE}
                    alt=''
                    className='h-12 cursor-pointer'
                    onMouseEnter={() => setRating(5)}
                    onMouseLeave={() => setRating(4)}
                    onClick={addRating}
                  />
                </div>
              ) : (
                <div className='my-4'>
                  <div className='flex space-x-3'>
                    {[oneE, twoE, threeE, fourE, fiveE].map((img) => (
                      <img src={img} alt='' className='h-12 cursor-pointer' />
                    ))}
                  </div>
                  <p className='text-md text-gray-500 my-5'>
                    Sign In to give Rating
                  </p>
                </div>
              )}
              <div className='text-gray-300 tracking-wide leading-relaxed'>
                {parse(character.characterDescription, options)}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='w-full h-screen flex justify-center'>
          <MagnifyingGlass
            visible={true}
            height='80'
            width='80'
            ariaLabel='MagnifyingGlass-loading'
            wrapperStyle={{}}
            wrapperClass='MagnifyingGlass-wrapper'
            glassColor='rgba(9, 151, 124, 0.1)'
            color='#00f6ff'
          />
        </div>
      )}
      <div className={`bg-primary ${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default CharacterPage;
