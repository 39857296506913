import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';

import one from '../../assets/rating/01-F.png';
import two from '../../assets/rating/02-F.png';
import three from '../../assets/rating/03-F.png';
import four from '../../assets/rating/04-F.png';
import five from '../../assets/rating/05-F.png';
import { MagnifyingGlass } from 'react-loader-spinner';

function AdminPage() {
  const [characterDataAndReviews, setCharacterDataAndReviews] = useState([]);

  const getReviews = async () => {
    console.log('Getting Data');
    const querySnapshot = await getDocs(collection(db, 'Characters'));
    const ratingsQuerySnapshot = await getDocs(collection(db, 'ratings'));
    const characterData = [];
    querySnapshot.forEach(async (document) => {
      const data = [];
      ratingsQuerySnapshot.forEach(async (queryDocument) => {
        if (queryDocument.data().characterId == document.id) {
          data.push({
            queryDocumentId: queryDocument.id,
            ...queryDocument.data(),
          });
        }
      });

      characterData.push({
        ...document.data(),
        id: document.id,
        ratings: data,
        averageRating:
          data.length > 0
            ? data.map((el) => el.rating).reduce((total, val) => total + val) /
              data.length
            : 0,
      });
    });

    setCharacterDataAndReviews(characterData);
  };
  console.log(characterDataAndReviews);
  useEffect(() => {
    getReviews();
  }, []);

  const getImage = (key) => {
    switch (key) {
      case 1:
        return one;
      case 2:
        return two;
      case 3:
        return three;
      case 4:
        return four;
      case 5:
        return five;
      default:
        return three;
    }
  };

  return (
    <div className='w-full sm:p-12 bg-primary '>
      <div className='px-4 md:px-10 py-4 md:py-7 bg-dimBlue rounded-tl-lg rounded-tr-lg'>
        <div className='sm:flex items-center justify-between'>
          <p className='text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal text-gray-100'>
            OO'sVille Ratings
          </p>
        </div>
      </div>
      <div className='bg-black px-4 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto'>
        <table className='w-full whitespace-nowrap bg-dimBlue'>
          <thead>
            <tr className='h-16 w-full text-sm leading-none text-gray-100'>
              <th className='font-medium text-lg text-left pl-4'>
                Character Name
              </th>
              <th className='font-medium text-lg text-left pl-6 lg:pl-12'>
                Average Rating
              </th>
              <th className='font-medium text-lg text-left pl-6 lg:pl-12'>
                Ratings
              </th>
            </tr>
          </thead>
          <tbody className='w-full'>
            {characterDataAndReviews.length > 0 ? (
              characterDataAndReviews.map((character) => (
                <tr
                  key={character.id}
                  className='h-20 text-sm leading-none text-gray-100 bg-gray-800 hover:bg-gray-700 border-b border-t border-gray-700'
                >
                  <td className='pl-4 cursor-pointer'>
                    <div className='flex items-center'>
                      <div className='w-12 h-12'>
                        <img
                          className='w-full h-full bg-gray-100'
                          src={character.characterImage[0].downloadURL}
                          alt={character.characterName}
                        />
                      </div>
                      <div className='pl-4'>
                        <p className='font-medium'>{character.characterName}</p>
                      </div>
                    </div>
                  </td>
                  <td className='pl-6 lg:pl-12'>
                    <p className='text-sm font-medium leading-none text-gray-100'>
                      {character.averageRating === 0
                        ? 'Not Rated'
                        : character.averageRating}
                    </p>
                    {/* <div className='w-24 h-3 bg-gray-100 rounded-full mt-2'>
                        <div className='w-20 h-3 bg-green-progress rounded-full' />
                      </div> */}
                  </td>
                  <td className='pl-6 lg:pl-12'>
                    <div className='flex space-x-2'>
                      {character.ratings
                        .filter((el, index) => index < 5)
                        .map((el) => (
                          <img src={getImage(el.rating)} className='h-8' />
                        ))}
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <div className='w-full h-screen flex justify-center'>
                <MagnifyingGlass
                  visible={true}
                  height='80'
                  width='80'
                  ariaLabel='MagnifyingGlass-loading'
                  wrapperStyle={{}}
                  wrapperClass='MagnifyingGlass-wrapper'
                  glassColor='rgba(9, 151, 124, 0.1)'
                  color='#00f6ff'
                />
              </div>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AdminPage;
