import React from 'react';
import styles from '../style';
import {
  Footer,
  Navbar,
  Hero,
  Team,
  FAQ,
  About,
  OOsVille,
  Roadmap,
} from '../components';
import CustomMarquee from '../components/Marquee';

import banner from '../assets/banner.png';

const HomePage = () => (
  <div className='bg-primary w-full overflow-hidden'>
    <div className={`${styles.paddingX} ${styles.flexCenter}`}>
      <div className={`${styles.boxWidth}`}>
        <Navbar />
      </div>
    </div>

    <div className={`bg-primary ${styles.flexStart}`}>
      <div className={`${styles.boxWidth}`}>
        <Hero />
      </div>
    </div>

    <div className={`bg-primary ${styles.paddingX} ${styles.flexCenter}`}>
      <div className={`${styles.boxWidth}`}>
        <About />
        <OOsVille />
      </div>
    </div>
    <CustomMarquee />

    <div className={`bg-primary ${styles.paddingX} ${styles.flexCenter}`}>
      <div className={`${styles.boxWidth}`}>
        <Roadmap />
        <img src={banner} alt='characters banner' width='100%' />
        <Team />
        <FAQ />
        <Footer />
      </div>
    </div>
  </div>
);

export default HomePage;
