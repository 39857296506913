import styles from '../style';
import { socialMedia } from '../constants';

const Footer = () => (
  <section className={`${styles.flexCenter} ${styles.paddingY} flex-col`}>
    <div className='flex flex-col items-center justify-around space-y-8'>
      <p className='font-poppins text-center text-3xl text-gray-300 text-gradient'>
        info@shintoon.com
      </p>{' '}
      <div className='flex flex-row'>
        {socialMedia.map((social, index) => (
          <img
            key={social.id}
            src={social.icon}
            alt={social.id}
            className={`w-16 h-16 object-contain cursor-pointer ${
              index !== socialMedia.length - 1 ? 'mr-6' : 'mr-0'
            }`}
            onClick={() => window.open(social.link)}
          />
        ))}
      </div>
      <p className='font-poppins font-normal text-center text-[16px] leading-[27px] text-gray-300'>
        Copyright Ⓒ 2022 Shintoon. All Rights Reserved.
      </p>
    </div>
  </section>
);

export default Footer;
