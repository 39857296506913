import { collection, getDocs } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { Footer, Navbar } from '../../components';
import { db } from '../../firebase';
import styles from '../../style';
import { MagnifyingGlass } from 'react-loader-spinner';
import SwipeCards from '../../components/SwipeCards';

const OOsVillePage = () => {
  const [characters, setCharacters] = useState([]);

  const getCharacters = async () => {
    console.log('Gettting Characters');
    const querySnapshot = await getDocs(collection(db, 'Characters'));
    const chars = [];
    querySnapshot.forEach((doc) => {
      chars.push({ ...doc.data(), id: doc.id });
      console.log(doc.id, ' => ', doc.data());
    });
    setCharacters(chars);
  };

  useEffect(() => {
    getCharacters();
  }, []);

  console.log(characters);
  const params = {
    effect: 'coverflow',
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: 'auto',
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    },
    pagination: {
      el: '.swiper-pagination',
    },
  };

  return (
    <div className='bg-primary w-full overflow-hidden text-white min-h-screen flex flex-col justify-between'>
      <div className={`${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}>
          <Navbar />
        </div>
      </div>
      <div className={`bg-primary ${styles.paddingX} ${styles.flexCenter}`}>
        {/* <div className={`${styles.boxWidth}`}> */}
        {characters.length > 0 ? (
          <SwipeCards characters={characters} />
        ) : (
          <div className='w-full h-screen flex justify-center items-center'>
            <MagnifyingGlass
              visible={true}
              height='80'
              width='80'
              ariaLabel='MagnifyingGlass-loading'
              wrapperStyle={{}}
              wrapperClass='MagnifyingGlass-wrapper'
              glassColor='rgba(9, 151, 124, 0.1)'
              color='#00f6ff'
            />
          </div>
        )}
        {/* </div> */}
      </div>
      <div className={`bg-primary ${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default OOsVillePage;
