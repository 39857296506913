import React from 'react';
import { Route, Routes } from 'react-router-dom';

import CreateAccountPage from './pages/auth/CreateAccountPage';
import SignInPage from './pages/auth/SignInPage';
import HomePage from './pages/HomePage';
import { ScrollingProvider } from 'react-scroll-section';
import OOsVillePage from './pages/OOsVille/OOsVillePage';
import CharacterPage from './pages/OOsVille/CharacterPage';
import AdminPage from './pages/OOsVille/AdminPage';
import RoadmapPage from './pages/RoadmapPage';

const App = () => {
  return (
    <ScrollingProvider>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/oosville' element={<OOsVillePage />} />
        <Route path='/roadmap' element={<RoadmapPage />} />
        <Route path='/admin' element={<AdminPage />} />
        <Route path='/oosville/:characterName' element={<CharacterPage />} />
        <Route path='/signin' element={<SignInPage />} />
        <Route path='/register' element={<CreateAccountPage />} />
      </Routes>
    </ScrollingProvider>
  );
};

export default App;
