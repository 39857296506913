import { sendPasswordResetEmail } from 'firebase/auth';
import React, { useState } from 'react';
import Modal from 'react-modal';
import { auth } from '../firebase';

import oosville from '../assets/oosville.png';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#05141B',
    border: 'none',
  },
};
Modal.defaultStyles.overlay.backgroundColor = '#090021';
Modal.setAppElement('#root');

const CustomModal = ({ closeModal, modalIsOpen }) => {
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);

  const sendPasswordResetEmailToUser = () => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setEmailSent(true);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorMessage);
      });
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel='Forgot Password'
    >
      <div className='flex flex-col items-center px-4 md:px-12 max-w-md'>
        {emailSent ? (
          <p className='text-base sm:text-lg md:text-2xl font-semibold md:leading-6 mb-4 mt-6 text-gray-300 text-center'>
            Password reset email sent!
          </p>
        ) : (
          <div>
            <p className='text-base sm:text-lg md:text-2xl font-bold md:leading-6 mb-2 mt-6 text-gray-300 text-center'>
              Forgot Your Password?
            </p>
            <div className='w-[100%] h-[100%] relative z-[5]'>
              <img src={oosville} alt='oosville' />
            </div>

            <p className='text-xs sm:text-sm leading-5 mt-2 text-center text-gray-600 dark:text-gray-300'>
              Don't worry it happens.Please enter the email address associated
              with your account.
            </p>
            <div className='flex items-center mt-4 sm:mt-6 w-full'>
              <div className='bg-gray-50 border rounded border-gray-200  w-full'>
                <input
                  type='email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className='w-full focus:outline-none pl-4 py-3 text-sm leading-none text-gray-700 bg-transparent placeholder-gray-500'
                  placeholder='Enter your email'
                />
              </div>
              <button
                onClick={() => sendPasswordResetEmailToUser()}
                disabled={email.length < 1}
                className='px-3 py-3 bg-cyan-500 dark:bg-cyan-400 focus:outline-none hover:bg-opacity-80 ml-2 rounded'
              >
                <svg
                  width={20}
                  height={20}
                  viewBox='0 0 20 20'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M1.62163 7.76261C1.18663 7.61761 1.18247 7.38345 1.62997 7.23428L17.5358 1.93261C17.9766 1.78595 18.2291 2.03262 18.1058 2.46428L13.5608 18.3693C13.4358 18.8101 13.1816 18.8251 12.995 18.4068L9.99997 11.6668L15 5.00011L8.3333 10.0001L1.62163 7.76261Z'
                    fill='white'
                  />
                </svg>
              </button>
            </div>
          </div>
        )}
      </div>
      <div
        onClick={closeModal}
        className='cursor-pointer absolute top-0 right-0 m-3 text-gray-800 transition duration-150 ease-in-out'
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          aria-label='Close'
          className='icon icon-tabler icon-tabler-x'
          width={20}
          height={20}
          viewBox='0 0 24 24'
          strokeWidth='2.5'
          stroke='#f7f7f7'
          fill='none'
          strokeLinecap='round'
          strokeLinejoin='round'
        >
          <path stroke='none' d='M0 0h24v24H0z' />
          <line x1={18} y1={6} x2={6} y2={18} />
          <line x1={6} y1={6} x2={18} y2={18} />
        </svg>
      </div>
    </Modal>
  );
};

export default CustomModal;
