import styles, { layout } from '../style';
import Button from './Button';
import about from '../assets/about.png';
import { useScrollSection } from 'react-scroll-section';

const About = () => {
  const oosVilleSection = useScrollSection('oosville');
  return (
    <section className={layout.section}>
      <div className={layout.sectionInfo}>
        <h2 className={styles.heading2}>Who Are We?</h2>
        <p className={`${styles.paragraph} max-w-[520px] mt-5`}>
          Shintoon is a company in the animation industry that is trying to
          bring the world of NFTs and gaming together. We are working on our
          very own game, OO’SVille, which will not only be available and star
          our NFT characters but go beyond what anybody could think of.
        </p>

        <Button onClick={oosVilleSection.onClick} styles={`mt-10`} />
      </div>

      <div className={layout.sectionImgReverse}>
        <div className='w-[100%] h-[100%] relative z-[5]'>
          <img src={about} alt='' />
        </div>

        {/* gradient start */}
        {/* <div className='absolute z-[3] -left-1/3 top-0 w-[50%] h-[50%] rounded-full white__gradient' /> */}
        {/* <div className='absolute z-[0] w-[50%] h-[50%] -right-1/2 bottom-0 rounded-full pink__gradient' /> */}
        {/* gradient end */}
      </div>
    </section>
  );
};

export default About;
