import styles from '../style';
import GetStarted from './GetStarted';

import hero from '../assets/hero.png';

const Hero = () => {
  return (
    <section
      id='home'
      className={`flex md:flex-row flex-col ${styles.paddingY}`}
    >
      <div
        className={`flex-1 ${styles.flexStart} flex-col xl:px-0 sm:px-16 px-6`}
      >
        {/* <div className='flex flex-row items-center py-[6px] px-4 bg-discount-gradient rounded-[10px] mb-2'>
          <img src={discount} alt='discount' className='w-[32px] h-[32px]' />
          <p className={`${styles.paragraph} ml-2`}>
            <span className='text-white'>OO'SVille </span>
            <span className='text-white'>25</span> unique charachters
          </p>
        </div> */}

        <div className='flex flex-row justify-between items-center w-full'>
          <h1 className='flex-1 font-poppins font-semibold ss:text-[72px] text-[52px] text-white ss:leading-[100.8px] leading-[75px]'>
            SHINTOON
            <br className='sm:block hidden' />{' '}
            <span className='text-gradient'>OO'SVille</span>{' '}
          </h1>
          <div className='ss:flex hidden md:mr-4 mr-0'>
            <GetStarted />
          </div>
        </div>
        <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
          Shintoon, an animation company, presenting it's Unique 25 Characters.
        </p>
      </div>

      <div className={`flex-1 flex ${styles.flexCenter} md:my-0 my-5 relative`}>
        <div className='w-[100%] h-[100%] relative z-[5]'>
          {/* <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: animationData,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
          /> */}
          <img src={hero} alt='' />
        </div>
        {/* gradient start */}
        <div className='absolute z-[0] w-[50%] h-[50%] right-20 bottom-20 blue__gradient' />
        <div className='absolute z-[0] w-[50%] h-[50%] right-20 bottom-20 pink__gradient' />
        {/* gradient end */}
      </div>

      <div className={`ss:hidden ${styles.flexCenter}`}>
        <GetStarted />
      </div>
    </section>
  );
};

export default Hero;
