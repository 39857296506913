import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-cube';
import 'swiper/css/pagination';
import 'swiper/css/keyboard';
import 'swiper/css/navigation';

import '../index.css';

// import required modules
import {
  EffectCoverflow,
  EffectCube,
  Keyboard,
  Navigation,
  Pagination,
} from 'swiper';
import { Link } from 'react-router-dom';
import useWindowDimensions from '../hooks/useWindowDimensions';

const SwipeCards = ({ characters }) => {
  const { height, width } = useWindowDimensions();
  console.log(width);
  return (
    <Swiper
      slidesPerView={'auto'}
      centeredSlides={true}
      spaceBetween={50}
      pagination={{
        clickable: true,
      }}
      modules={[Pagination, Navigation, Keyboard]}
      className='mySwiper'
      navigation={width > 500}
      keyboard
    >
      {characters.map((character) => (
        <SwiperSlide>
          <Link to={`/oosville/${character.characterName}`}>
            <div className='sm:w-full sm:h-auto md:w-96 md:h-96 bg-black-gradient'>
              <h3 className='cursor-pointer text-[#F8DDD2] text-xl font-semibold my-3'>
                {character.characterName}
              </h3>
              <div className='px-8'>
                <img
                  src={character.characterImage[0].downloadURL}
                  alt={character.characterName}
                  className='object-contain h-full w-full'
                />
              </div>
            </div>
          </Link>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SwipeCards;
