import styles, { layout } from '../style';
import oosville from '../assets/oosville.png';
import { Section } from 'react-scroll-section';

const Billing = () => (
  <Section id='oosville' className={layout.sectionReverse}>
    <div className={layout.sectionImgReverse}>
      <div className='w-[100%] h-[100%] relative z-[5]'>
        <img src={oosville} alt='' />
      </div>

      {/* gradient start */}
      <div className='absolute z-[3] -left-1/2 top-0 w-[50%] h-[50%] rounded-full white__gradient' />
      <div className='absolute z-[0] w-[50%] h-[50%] -left-1/2 bottom-0 rounded-full pink__gradient' />
      {/* gradient end */}
    </div>

    <div className={layout.sectionInfo}>
      <h2 className={`${styles.heading2} text-gradient`}>OO’SVille</h2>
      <p className={`${styles.paragraph} max-w-[520px] mt-5`}>
        OO’SVille is the NFT collection that we have designed with 25 unique
        characters each with a fantastic personality and backstory. These are
        the same characters that will be available in the game and by buying one
        of the NFTs you will be able to join in and be one of the exclusive few
        who get to enjoy the game and our animations.
      </p>
    </div>
  </Section>
);

export default Billing;
