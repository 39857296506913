import React from 'react';
import { Footer, Navbar, Roadmap } from '../components';
import styles from '../style';

const RoadmapPage = () => {
  return (
    <div className='bg-primary w-full overflow-hidden min-h-screen'>
      <div className={`${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}>
          <Navbar />
          <Roadmap />
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default RoadmapPage;
