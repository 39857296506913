import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { doc, serverTimestamp, setDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

import Sina from '../../assets/Sina.webp';

const CreateAccountPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const [showpass, setShowPass] = useState(false);

  const navigate = useNavigate();

  const [user, loading, error] = useAuthState(auth);

  useEffect(() => {
    if (user) {
      return navigate('/');
    }
  }, [user]);

  const createUserAccount = () =>
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        updateProfile(auth.currentUser, {
          displayName: `${firstName} ${lastName}`,
        }).then(async () => {
          const user = userCredential.user;
          const data = {
            createdAt: serverTimestamp(),
            displayName: user.displayName,
            email: user.email,
            id: user.uid,
          };

          await setDoc(doc(db, 'users', user.uid), data);
          navigate('/', { replace: true });
        });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
      });

  return (
    <div className='bg-primary min-h-screen flex items-center'>
      <div className='xl:px-20 md:px-10 sm:px-6 px-4 md:py-12 py-9 2xl:mx-auto 2xl:container md:flex items-center justify-center'>
        <div className='bg-dimBlue shadow-lg rounded xl:w-1/3 lg:w-5/12 md:w-1/2 w-full sm:px-10 sm:py-10 px-6 py-12'>
          <p
            tabIndex={0}
            className='focus:outline-none text-2xl font-extrabold leading-6 text-gray-300'
          >
            Create your account
          </p>
          <p
            tabIndex={0}
            className='focus:outline-none text-sm mt-4 font-medium leading-none text-gray-400'
          >
            Already have an account?{' '}
            <span
              onClick={() => navigate('/signin')}
              className='hover:text-gray-400 focus:text-gray-400 focus:outline-none focus:underline hover:underline text-sm font-medium leading-none text-gray-300 cursor-pointer'
            >
              Sign in here
            </span>
          </p>
          <hr className='w-full bg-gray-400 my-6' />
          <div className='mt-4 grid grid-cols-1 md:grid-cols-2 gap-4'>
            <div className=''>
              <label className='text-sm font-medium leading-none text-gray-300'>
                {' '}
                First Name{' '}
              </label>
              <input
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className='bg-gray-200 border rounded text-xs font-medium leading-none placeholder-gray-500 text-gray-800 py-3 w-full pl-3 mt-2'
              />
            </div>
            <div className=''>
              <label
                htmlFor='lastName'
                className='text-sm font-medium leading-none text-gray-300'
              >
                {' '}
                Last Name{' '}
              </label>
              <input
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className='bg-gray-200 border rounded text-xs font-medium leading-none placeholder-gray-500 text-gray-800 py-3 w-full pl-3 mt-2'
              />
            </div>
          </div>
          <div className='mt-6'>
            <label
              htmlFor='email'
              className='text-sm font-medium leading-none text-gray-300'
            >
              {' '}
              Email{' '}
            </label>
            <input
              id='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              aria-labelledby='email'
              type='email'
              className='bg-gray-200 border rounded text-xs font-medium leading-none placeholder-gray-500 text-gray-800 py-3 w-full pl-3 mt-2'
              placeholder='e.g: john@gmail.com '
            />
          </div>
          <div className='mt-6 w-full'>
            <label
              htmlFor='password'
              className='text-sm font-medium leading-none text-gray-300'
            >
              {' '}
              Password{' '}
            </label>
            <div className='relative flex items-center justify-center'>
              <input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                id='password'
                type={showpass ? 'text' : 'password'}
                className='bg-gray-200 border rounded text-xs font-medium leading-none text-gray-800 py-3 w-full pl-3 mt-2'
              />
              <div
                onClick={() => setShowPass(!showpass)}
                className='absolute right-0 mt-2 mr-3 cursor-pointer'
              >
                <div id='show'>
                  <svg
                    width={16}
                    height={16}
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M7.99978 2C11.5944 2 14.5851 4.58667 15.2124 8C14.5858 11.4133 11.5944 14 7.99978 14C4.40511 14 1.41444 11.4133 0.787109 8C1.41378 4.58667 4.40511 2 7.99978 2ZM7.99978 12.6667C9.35942 12.6664 10.6787 12.2045 11.7417 11.3568C12.8047 10.509 13.5484 9.32552 13.8511 8C13.5473 6.67554 12.8031 5.49334 11.7402 4.64668C10.6773 3.80003 9.35864 3.33902 7.99978 3.33902C6.64091 3.33902 5.32224 3.80003 4.25936 4.64668C3.19648 5.49334 2.45229 6.67554 2.14844 8C2.45117 9.32552 3.19489 10.509 4.25787 11.3568C5.32085 12.2045 6.64013 12.6664 7.99978 12.6667ZM7.99978 11C7.20413 11 6.44106 10.6839 5.87846 10.1213C5.31585 9.55871 4.99978 8.79565 4.99978 8C4.99978 7.20435 5.31585 6.44129 5.87846 5.87868C6.44106 5.31607 7.20413 5 7.99978 5C8.79543 5 9.55849 5.31607 10.1211 5.87868C10.6837 6.44129 10.9998 7.20435 10.9998 8C10.9998 8.79565 10.6837 9.55871 10.1211 10.1213C9.55849 10.6839 8.79543 11 7.99978 11ZM7.99978 9.66667C8.4418 9.66667 8.86573 9.49107 9.17829 9.17851C9.49085 8.86595 9.66644 8.44203 9.66644 8C9.66644 7.55797 9.49085 7.13405 9.17829 6.82149C8.86573 6.50893 8.4418 6.33333 7.99978 6.33333C7.55775 6.33333 7.13383 6.50893 6.82126 6.82149C6.5087 7.13405 6.33311 7.55797 6.33311 8C6.33311 8.44203 6.5087 8.86595 6.82126 9.17851C7.13383 9.49107 7.55775 9.66667 7.99978 9.66667Z'
                      fill='#71717A'
                    />
                  </svg>
                </div>
                <div id='hide' className='hidden'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='icon icon-tabler icon-tabler-eye-off'
                    width={16}
                    height={16}
                    viewBox='0 0 24 24'
                    strokeWidth='1.5'
                    stroke='#27272A'
                    fill='none'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  >
                    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                    <line x1={3} y1={3} x2={21} y2={21} />
                    <path d='M10.584 10.587a2 2 0 0 0 2.828 2.83' />
                    <path d='M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341' />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className='mt-8'>
            <button
              role='button'
              disabled={
                email.length < 1 ||
                password.length < 1 ||
                firstName.length < 1 ||
                lastName.length < 1
              }
              onClick={createUserAccount}
              className=' disabled:bg-slate-400 text-sm font-semibold leading-none focus:outline-none text-primary bg-cyan-300 py-4 w-full'
            >
              Create my account
            </button>
          </div>
        </div>
        <div className='xl:w-1/3 md:w-1/2 lg:ml-16 md:mt-0 mt-6'>
          <div className='block'>
            <h1 className='font-poppins xl:text-4xl text-3xl text-gray-300 font-medium text-gradient'>
              Shintoon.io
            </h1>
          </div>
          <div className='flex items-start mt-4'>
            <p className='sm:text-2xl text-xl leading-7 text-gray-100'>
              Shintoon is a company in the animation industry that is trying to
              bring the world of NFTs and gaming together. We are working on our
              very own game, OO’SVille, which will not only be available and
              star our NFT characters but go beyond what anybody could think of.
            </p>
          </div>
          <div className='flex items-center mt-10'>
            <div className='w-8 h-8'>
              <img
                src={Sina}
                alt='profile picture'
                className='w-full h-full rounded-full'
              />
            </div>
            <div className='ml-2'>
              <p className='text-sm font-medium leading-none text-gray-200'>
                Sina
              </p>
              <p className='text-sm font-medium leading-none text-gray-400 mt-1 cursor-pointer hover:underline'>
                Founder &amp; Art Director
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAccountPage;
